<template>
  <div class="about">
    <div class="banner">
      <img src="https://image.ceekee.com/pc/tripwise/20200110_28.png" />
    </div>
    <div class="aboutSectionOne">
      <img src="https://image.ceekee.com/pc/tripwise/20200110_29.png" />
      <div class="intro">
        <p>
          差旅管家是苏州大管家信息科技有限公司旗下的新一代商务出行平台，以高度数智化能力和供应链聚合能力，为超过1500家大中型企业的500万用户，提供商务出行的综合性数字化解决方案，创造极致的预订使用体验。
        </p>
        <p>
          差旅管家实现了企业消费场景和支付场景的全覆盖，支持与主流OA/财务/费控系统的无缝对接，提供会员权益贯通和同屏比价功能，使客户享受极致节省的价格体系，赋能客户提升差旅管理效率。
        </p>
      </div>
      <div id="videoPlay">
        <video
          width="845"
          height="475"
          controls
          preload="none"
          poster="https://image.ceekee.com/pc/tripwise/20210112_1.jpg"
        >
          <source src="https://appdownload.ceekee.com/video/video.mp4?v=1" type="video/mp4" />
          您的浏览器不支持Video标签。
        </video>
      </div>
    </div>

    <!-- 风险规避，注释所获荣誉 -->
    <!-- <img class="sectionTwo" src="https://image.ceekee.com/pc/tripwise/20200110_30.png" /> -->

    <div class="sectionFour">
      <img src="https://image.ceekee.com/pc/tripwise/20200110_36.png" />
      <div class="content">
        <img src="https://image.ceekee.com/pc/tripwise/20200110_35.png" />
        <img src="https://image.ceekee.com/pc/tripwise/20200110_34.png" />
        <img src="https://image.ceekee.com/pc/tripwise/20200110_33.png" />
        <img src="https://image.ceekee.com/pc/tripwise/20200110_32.png" />
      </div>
    </div>

    <Contact></Contact>
  </div>
</template>

<script>
import Player from "xgplayer";
import Contact from "@/components/Contact.vue";
export default {
  components: {
    Contact,
  },
  mounted() {},
};
</script>

<style scoped lang="less">
.about {
  .banner {
    padding: 10% 0 9%;
    width: 100%;
    overflow: hidden;
    background: url(https://image.ceekee.com/pc/tripwise/20200110_27.png) center no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 50%;
      max-width: 1014px;
    }
  }
  .aboutSectionOne {
    width: 85%;
    max-width: 1100px;
    margin: 0 auto;
    & > img {
      display: block;
      margin: 3% auto;
      width: 15%;
    }
    .intro {
      line-height: 36px;
      font-size: 16px;
      color: #666666;
      letter-spacing: 0;
      p {
        text-indent: 40px;
      }
    }
    #videoPlay {
      width: 845px;
      height: 475px;
      margin: 50px auto 70px;
    }
  }
  .sectionTwo {
    width: 100%;
  }
  .sectionFour {
    width: 100%;
    padding-bottom: 50px;
    overflow: hidden;
    background: url(https://image.ceekee.com/pc/tripwise/20200110_31.png) center 0 no-repeat;
    & > img {
      display: block;
      margin: 6% auto 4%;
      width: 19%;
    }
    .content {
      width: 75%;
      max-width: 1200px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      img {
        width: 48%;
        margin-bottom: 4%;
      }
    }
  }
}
</style>
